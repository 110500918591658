.grid-mainContainer {
  display: grid;
  grid-template-areas: 'alert alert'
                       'header header' 
                       'h2 h2'
                       'filters filters'
                       'tableData tableData'
                       ;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  /* background-color: #2196F3; */
  padding: 10px;
  width: 90%;
  margin: auto;

}
.item1 {
  grid-area: header;
  font-size: 18px;
  margin: 0;
}
.item2 {
  grid-area: h2;
}


.item4 {
  margin-top: 15px;
  grid-area: tableData;
}

.grid-mainContainer > .item5 {
  grid-area: alert ;
}
.rendering-zone {
  font-size: 14px;
  
}
.MuiDataGrid-colCellWrapper {
  font-size: 15px;
  text-align: center;
}

.MuiTablePagination-root{
  font-size: 15px;
}

.MuiTablePagination-toolbar {
  font-size: 16px;
}

.MuiToolbar-regular > p {
  font-size: 14px;
}

.MuiAppBar-colorPrimary {
  background-color: #bf2349 !important;
}

.tituloModalView {
  font-size: 18px;
  padding: 10px 20px 20px 20px;
}

.inputDate {
  border: none;
  padding: 9px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 5px;
  /* margin-top: 25px; */
  min-width: 100% !important;
  /* height: 40px; */
}
.inputDateManagementIncidence {
  border: none;
  padding: 9px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 5px;
  /* margin-top: 25px; */
  min-width: 100% !important;
  height: 40px;
}

.inputDateManagementIncidence:focus {
  outline: none !important;
  border:2px solid #bf2349;

}

.contentModalViewInputs > .selectInputs > .css-1pahdxg-control{
  min-height: 49px !important;
  z-index: 1000 !important;
}

.react-datepicker {
  font-size: 1.1rem;
}

.grid-containerFilters {
  display: grid;
  /* grid-template-areas: 'header header' 
                       'h2 h2'
                       ; */
  grid-gap: 10px;
  grid-template-columns: 250px 250px;
  /* background-color: #2196F3; */
  width: 73%;
}
.item3 {
  grid-area: filters;
}



.buttonMenu > span {
  font-size: 14px;
}

.contentModalView {
  background: #fff;
  box-shadow: black;
  padding: 20px;
  min-height: 58%;
  max-height: 95%;
  max-width: 40%;
  min-width: 660px;
  width: 40%;
  display: grid;
  grid-template-rows: 70px 1fr 1fr;
  overflow: hidden;
  outline: none;
  border-radius: 5px;
  
}
.errorLabel {
  grid-column: 1;
  grid-row: 0 / 0;
  text-decoration: underline;
}
.contentModalViewInputs {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
  width: 80%;
  word-wrap: break-word;
  height: 100%;
}

.additional-options-counter {
  display: inline-block;
  padding: 2px 8px;
  background-color: #ccc;
  border-radius: 10px;
  margin-left: 4px; /* Ajusta el margen según sea necesario */
  font-size: 0.8em;
  color: #333;
}

.inputDate {
  margin-top: 0;
}

.observationTextAreaContainer {
  display: grid;
  grid-template-areas: 'total total';
  height: 100%;
  padding: 20px;
  width: 87%;
  margin: auto;
  padding-top: 0;
  margin-top: 50px;
}

.observationTextAreaContent {
  grid-area: total;
  resize: none;
  height: 100%;
}

.textArea {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}



.textArea:focus { 
  border-color: #bf2349;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  gap: 10px;
  padding: 20px;
  padding-top: 30px;
  width: 87%;
  margin: auto;
  margin-top: 48px;
}

.buttonsContainer2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px;
  gap: 10px;
  padding: 20px;
  padding-top: 0;
  width: 87%;
  margin: auto;
  margin-top: 63px;
}

.btnTemporalAccess {
  height: 40px !important;
  border-radius: 5px !important;
}

/* .selectInputs > .css-2b097c-container > .css-yk16xz-control{
  min-height: 49px !important;
} */

/* .css-yk16xz-control{
  min-height: 40px !important;
} */
.selectInputs > .css-26l3qy-menu {
  margin-top: 20px !important;
  
}

.MuiAlert-message {
  flex-direction: unset !important;
}

.contentModalViewInputs > .selectInputs > .MuiInputBase-formControl > .MuiInputBase-input {
  font-family: sans-serif;
  letter-spacing: 0;
  margin-top: 5px;
  /* height: 49px !important; */
  
}

.MuiDataGrid-checkboxInput > .MuiIconButton-label {
  color:#bf2349;
}
.MuiCheckbox-colorSecondary {
  background:#bf2349;
}

.MuiDialogActions-root >.MuiButton-text > .MuiButton-label {
  color: #bf2349;
  font-size: 14px;
}

.MuiFormHelperText-marginDense {
  margin-top: 5px !important;
}

.ul {
  width: 100%;
  position: relative;
  overflow: auto;
  max-width: 360px;
  max-height: 300px;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 8px;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.root {
  width: 50%;
  max-width: 360px;
  background-color: "#fff";
  position: 'relative';
  overflow: 'auto';
  max-height: 300px;
}

.li {
  display: flex;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  padding-bottom: 16px;
  justify-content: center;
  background-color: #eeeeee;
}

.MuiListSubheader-root{
  line-height: 34px !important;
  font-size: 14px !important;
}

.MuiListItemText-primary {
  font-size: 12px !important;
}

.contentModalViewInputs > .selectInputs > .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-inputMarginDense {
  margin-top: 5px !important;
  border-color: green !important;
  letter-spacing: 0 !important;
}

.inputDateEnd {
  border-color: red !important;
}


.css-p0b4fk-group {
  color: red !important;
}

.css-1hb7zxy-IndicatorsContainer{
  transition: unset !important;
}

/* input[type="text"]
{
  display:block;

 
   -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  min-height: 1.2em; 

  
  min-width: 96%;
} */

.MuiDataGrid-root .MuiDataGrid-overlay {
  z-index: -1 !important;
}


.react-datepicker-wrapper {
  width: 100% !important;
}
.MuiGrid-spacing-xs-2 {
  width: calc(50% + 16px) !important; 
}
#fade > .MuiGrid-root {
  width: calc(50% + 16px) !important; 
  margin: auto !important;
  background: #fff !important;
  padding: 15px;
}

@media screen and (max-width: 767px){
  /* .react-datepicker-wrapper {
      width: 36vw !important;
  } */
  .MuiGrid-root  {
      width: 100% !important;
      margin: auto !important;
      background: #fff !important;
      padding: 15px;
  }
  .grid-mainContainer {
      display: grid;
      grid-template-areas: 'alert alert'
                           'header header' 
                           'h2 h2'
                           'filters filters'
                           'tableData tableData'
                           ;
      grid-gap: 10px;
      grid-template-columns: 1fr;
      /* background-color: #2196F3; */
      padding: 10px;
      width: 90%;
      margin: auto;
  
  }
  .grid-containerFilters {
      width: 100%;
      display: grid;
      /* grid-template-areas: 'header header' 
                           'h2 h2'
                           ; */
      grid-gap: 10px;
      grid-template-columns: 100%;
      /* background-color: #2196F3; */
  }
}
.hr-theme-slash-2 {
  display: flex;
}

.hr-theme-slash-2 > .hr-line {
  width: 100%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.hr-theme-slash-2 > .hr-icon {
  position: relative;
  top: 3px;
  color: #32a852;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-right: 0px !important;
}



/* @media screen and (max-width: 990px){
  .react-datepicker-wrapper {
      width: 65vw !important;
  }
} */

