/* @import url('https://fonts.googleapis.com/css?family=Lato:400,700,900'); */
body {
	font-family: 'Lato', sans-serif;
}

html,
body {
	height: 100%;
}

.wrapper {
	min-height: 100%;
	/*position: relative;*/
	background: #fff;
}

.inner-container {
	padding-bottom: 80px;
}

.navbar-default {
	background: none;
	border: 0;
	padding: 40px 0 0 0;
}

.navbar-right {
	padding-right: 20px;
}

.navbar-nav>li {
	position: relative;
	padding: 0 12px;
}

/* .navbar-nav>li::after{content: ""; width: 1px; height: 10px; background: #575656; position: absolute; top: 0; right: 0; bottom: 0; margin: auto;} */
.navbar-nav>li:last-child {
	padding-right: 0;
}

.navbar-nav>li:last-child::after {
	display: none;
}

/* .navbar-default .navbar-nav>li>a{padding: 0; color: #575656; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;}
.navbar-default .navbar-nav>li>a:hover{color: #cd0438; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;}
.navbar-default .navbar-nav>li>a::after{content: ""; width: 10px; height: 1px; opacity: 0; background: #cd0438; position: absolute; bottom: 0; left: 0; right: 0; margin: auto; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;}
.navbar-default .navbar-nav>li>a:hover::after{width: 100%; opacity: 1; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;} */
.navbar-default .navbar-toggle {
	border: none;
}

.navbar-default .navbar-toggle .icon-bar {
	background: #231f20;
	width: 25px;
	height: 4px;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
	background: none;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border: 0;
	box-shadow: none;
}

.nav-title {
	display: none;
	font-size: 12px;
	margin: 20px 0 0 0;
}

.container-fluid>.navbar-header {
	margin: 16px 0 0 0;
	float: left;
}

.navbar-default .navbar-toggle {
	margin: 0;
	padding: 0;
	width: 30px;
	height: 23px;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	top: 60px;
}

.navbar-default .navbar-toggle.collapsed .icon-bar {
	position: relative;
}

.navbar-default .navbar-toggle .icon-bar {
	position: absolute;
}

.navbar-default .navbar-toggle.collapsed .icon-bar:nth-child(2) {
	transform: none;
	-moz-transform: none;
	-webkit-transform: none;
	-o-transform: none;
	-ms-transform: none;
	top: 0;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.navbar-default .navbar-toggle.collapsed .icon-bar:nth-child(3) {
	opacity: 1;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	top: 0;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.navbar-default .navbar-toggle.collapsed .icon-bar:nth-child(4) {
	transform: none;
	-moz-transform: none;
	-webkit-transform: none;
	-o-transform: none;
	-ms-transform: none;
	top: 0;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.navbar-default .navbar-toggle .icon-bar:nth-child(2) {
	transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	top: 9px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.navbar-default .navbar-toggle .icon-bar:nth-child(3) {
	opacity: 0;
	top: 5px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.navbar-default .navbar-toggle .icon-bar:nth-child(4) {
	transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	top: 5px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.main-form-container {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 360px;
	transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.logo-container {
	text-align: center;
}

.logo-container a {
	display: inline-block;
}

.form-container {
	margin: 50px auto 0;
	padding: 0;
}

.form-container li {
	list-style: none;
	margin: 0 0 10px 0;
}

.form-container li input[type="text"],
.form-container li input[type="password"] {
	width: 100%;
	padding: 10px;
	border: 0;
	height: 60px;
	color: #9c9d9e;
	font-size: 15px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.form-container li input[type="text"]:focus,
.form-container li input[type="password"]:focus {
	box-shadow: 0 0 12px rgba(191, 35, 73, 0.3);
	outline: none;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.btn-default {
	background: #bf2349;
	border: 0;
	border-radius: 0;
	color: #ffffff;
	width: 100%;
	height: 60px;
	font-size: 15px;
	font-weight: 700;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.btn-default:hover {
	background: #4a4a4a;
	color: #ffffff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.btn-default.focus,
.btn-default:focus {
	outline: 1px auto #e48da2;
}

.btn-default[disabled] {
	background-color: #bf2349 !important;
	border-color: #cccccc !important;
}

.header-container {
	background: #bf2349;
	padding: 20px 50px;
}

.header-container .navbar {
	margin-bottom: 0;
}

.header-container .navbar-collapse {
	padding-right: 0;
}

.header-container .navbar-default {
	padding-top: 0;
}

.header-container .logo-container {
	float: left;
}

.header-links-container {
	float: right;
	margin: 10px 0 0 0;
}

.header-links {
	float: left;
	margin: 0 40px 0 0;
}

.header-container .navbar-default .navbar-collapse {
	float: left;
	margin: 7px 0 0 0;
}

.header-links ul {
	margin: 0;
	padding: 0;
}

.header-links ul li {
	float: left;
	list-style: none;
	font-size: 22px;
	margin: 0 30px 0 0;
}

.header-links ul li:last-child {
	margin-right: 0;
}

.header-links ul li a {
	color: #ffffff;
}

.header-container .navbar-default .navbar-nav>li>p {
	color: #ffffff;
}

.header-container .navbar-default .navbar-nav>li>p::after {
	background: #ffffff;
}

.header-container .navbar-nav>li {
	padding: 0 40px 0 0;
}

.header-container .navbar-nav>li:last-child {
	padding-right: 0;
}

.header-container .navbar-nav>li::after {
	display: none;
}

.inner-container {
	margin-top: 30px;
	padding-left: 30px;
	padding-right: 30px;
}

.map-content {
	background: #ffffff;
	padding: 30px 40px 60px;
}

.map-content ul {
	margin: 0;
	padding: 0;
}

.map-content ul li {
	list-style: none;
	font-size: 22px;
	margin: 0 0 10px 0;
}

.map-content ul li .icon {
	float: left;
	width: 45px;
	text-align: center;
}

.map-content ul li .map-text {
	float: left;
	width: 87%;
}

.map-content ul li .measurement-unit {
	float: left;
}

.map-content ul li.measurement .icon {
	margin-top: 3px;
}

.check {
	color: #9c9d9e;
	font-size: 16px;
	font-weight: 700;
	width: 45px;
	text-align: center;
	float: left;
	margin: 7px 0 0 0;
}

.check-content {
	font-weight: 700;
	font-size: 25px;
	float: left;
}

.map-content .svg-inline--fa {
	color: #7f8080;
}

.map-content .fa-arrows-alt-h {
	font-size: 24px;
	line-height: 20px;
}

.map-content .fa-user {
	font-size: 18px;
}

.map-content .btn-default {
	margin: 60px 0 0 0;
	width: auto;
	padding: 0 80px;
	height: 50px;
}

.map-content .btn-default .fa-unlink {
	margin: 0 10px 0 0;
	color: #000000;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.map-content .btn-default:hover .fa-unlink {
	color: #ffffff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.map-images {
	background: #ffffff;
	margin: 30px 0 0 0;
	padding: 5px 5px 60px;
}

.map-images ul {
	margin: 0;
	padding: 0;
}

.map-images ul li {
	list-style: none;
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
}

.map-images ul li img {
	max-height: 100px;
}

.map-images ul li a:focus {
	outline: 0;
}

.map-images ul li a {
	display: block;
	overflow: hidden;
}

/* popup */
/* .modal{background: rgba(0,0,0,0.3);} */
/* .modal-content{background: none; box-shadow: none; border: 0;}
.modal-header .close{color: #ffffff; opacity: 1; font-size: 65px; text-shadow: none; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;}
.modal-header .close:hover{color: #bf2349; text-shadow: 0 1px 0 #fff; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;}
.popup-map-container{background: #ffffff; padding: 0;} */
/* .popup-map-content{padding: 20px 20px 50px;}
.popup-img img{border: 20px solid #f5f5f5;} */
/* .modal-header .close:focus{outline: 0;} */
/* .popup-map-content .date{margin: 0 5px 0 0;} */
/* .modal.in .modal-dialog{padding: 0 25px; margin-top: 10px;} */

.swiper-container {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

/* Link */
.link-main-container {
	padding-bottom: 80px;
}

.link-container {
	width: 100%;
	background: #ffffff;
	padding: 20px 65px;
}

/* .link-header{padding: 0 65px;} */
.link-container h1 {
	width: 20%;
	margin: 0;
	float: left;
	color: #706f6f;
	font-size: 25px;
	margin: 8px 0 0 0;
}

.link-container .btn-container {
	width: 80%;
	float: right;
	text-align: right;
}

.link-container .btn-default {
	width: auto;
	height: 45px;
	text-transform: none;
	margin: 0 0 0 20px;
	padding: 0 45px;
}

.link-container .btn-default .fa-unlink {
	color: #000000;
	margin: 0 5px 0 0;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.link-container .btn-default:hover .fa-unlink {
	color: #ffffff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.link-content {
	margin: 50px 0 0 0;
}

.link-content ul {
	margin: 0;
	padding: 0;
}

.link-content ul li {
	list-style: none;
	margin: 0 0 30px 0;
}

.link-content ul li .link-title {
	background: #c6c6c6;
	color: #3c3c3b;
	padding: 10px 20px;
	margin: 0 0 20px 0;
}

.link-content ul li .link-title .icon {
	width: 20px;
	display: block;
	float: left;
	text-align: center;
	margin: 0 10px 0 0;
}

.link-content ul li .link-title .link-text {
	display: block;
	float: left;
}

.link-content ul li .link-title .icon .fa-caret-right {
	font-size: 22px;
	color: #808081;
}

.link-content ul li .link-title .icon .fa-user {
	color: #808081;
	font-size: 16px;
}

.link-content ul li .details label {
	margin: 0;
	float: left;
	width: 20%;
	font-weight: normal;
	color: #9c9e9f;
}

.link-content ul li .details li {
	font-size: 20px;
	border-bottom: 1px solid #9d9d9c;
	padding: 0 0 7px 0;
	margin: 0 0 10px 0;
}

.link-content ul li .details .content {
	float: left;
	width: 80%;
	padding: 0 0 0 15px;
}

.link-content ul li .details {
	padding: 0 20px;
}

/* .select-box{width: 350px;} */
.select-box .select {
	cursor: pointer;
	display: inline-block;
	position: relative;
	color: #808080;
	line-height: 25px;
	font-size: 12px;
	width: 100%;
	height: 30px;
}

select.selectArrow {
	-moz-appearance: none;
	appearance: none;
	-webkit-appearance: none;
	border: 1px solid #a8a8a8;
	color: #888;
	cursor: pointer;
	display: inline-block;
	width: 100%;
	border: 0;
	color: #323a45;
}

/* .styledSelect{background:url("../images/down-arrow.png") no-repeat 100% 8px; position:absolute; top:0; right:0; bottom:0; left:0; background-color:#FFFFFF; padding:0 35px 0 0; font-size: 20px; color: #323a45; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} */
.styledSelect:hover {
	background-position: 100% -18px;
	color: #c0234a;
}

.select-box .options {
	background-color: #f6f6f6;
	color: #808080;
	display: none;
	position: absolute;
	right: 0;
	left: 0px;
	margin: 0 0 0 0;
	line-height: 25px;
	list-style: none;
	width: 100%;
	top: 38px;
	z-index: 1;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.select-box .options li {
	margin: 0 0 0 0;
	display: block;
	clear: both;
	padding: 5px 15px;
	color: #323a45;
	font-size: 18px;
}

.select-box .options li:last-child {
	border-bottom: 0;
}

.link-content ul li .select-box .options li {
	padding: 10px 15px;
	margin-bottom: 0;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	word-wrap: break-word;
}

.select-box .options li:hover {
	background: #eeeeee;
	color: #c0234a;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

footer {
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
}

footer a {
	color: #a7acad;
	position: relative;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

footer a:hover {
	text-decoration: none;
	color: #cd0438;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

footer a::after {
	content: "";
	width: 10px;
	height: 1px;
	opacity: 0;
	background: #cd0438;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

footer a:hover::after {
	width: 100%;
	opacity: 1;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

/* Clases creadas por jose */

.iconExclamation {
	position: absolute;
	top: 46.5%;
	left: 20px;
	width: 30px;
	height: 30px;
}

.containerTextInactivity {
	background: #fff;
	font-size: 13px;
	width: 100%;
	height: 100%;
	margin-top: 30px;
	border-radius: 8px;
	padding: 25px;
	padding-left: 65px;
	padding-right: 25px;
}

.aButton {
	height: 45px;
	line-height: 2.2;
	width: 67%;
	margin-left: 57px;
}

.aClass:hover {
	color: #cd0438;
	text-decoration: underline;
}

.aClass:hover {
	text-decoration: none;
	color: #cd0438;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.aClass::after {
	content: "";
	width: 10px;
	height: 1px;
	opacity: 0;
	background: #cd0438;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.aClass:hover::after {
	width: 40%;
	opacity: 1;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

.form-control {
	height: 38px;
}

.button-recoverPassword {
	background-color: #d9dadb;
	border: none;
	color: rgb(133, 133, 133);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
}

.button-recoverPassword:hover {
	color: #cd0438;
	text-decoration: underline;
}

.button-recoverPassword:hover {
	text-decoration: none;
	color: #cd0438;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}

/* .button-recoverPassword::after{content: ""; width: 10px; height: 1px; opacity: 0; background: #cd0438; position: absolute; bottom:7%; left: 0; right: 0; margin: auto; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;}
.button-recoverPassword:hover::after{width: 40%; opacity: 1; transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; -ms-transition: all 0.5s ease;} */



.pin {
	width: 70px;
	height: 70px;
	border-radius: 50% 50% 50% 0;
	background: #be2852;
	position: absolute;
	/* transform: rotate(-45deg); */
	left: 47%;
	top: 50%;
	margin: -30px 0 0 -4px;
	z-index: 4;
}

.imgLogo {
	position: absolute;
	top: 37%;
	left: 42.5%;
}

.pin:after {
	content: "";
	width: 34px;
	height: 34px;
	margin: 8px 0 0 8px;
	background: #fff;
	position: absolute;
	border-radius: 50%;
	top: 8px;
	right: 16px;
}

.bounce {
	animation-name: bounce;
	animation-fill-mode: both;
	animation-duration: 1s;
}

.bounceImg {
	animation-name: bounceImg;
	animation-fill-mode: both;
	animation-duration: 1s;
}

.pulse {
	background: #d6d4d4;
	border-radius: 50%;
	height: 64px;
	width: 64px;
	position: absolute;
	left: 47%;
	top: 50%;
	margin: 22px 0px 0px -1px;
	transform: rotateX(55deg);
	z-index: 2;
}

.pulse:after {
	content: "";
	border-radius: 50%;
	height: 84px;
	width: 84px;
	position: absolute;
	margin: -7px 0 0 -9px;
	-moz-animation: pulsate 1s ease-out;
	-moz-animation-iteration-count: infinite;
	-webkit-animation: pulsate 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation: pulsate 1s ease-out;
	animation-iteration-count: infinite;
	opacity: 0;
	box-shadow: 0 0 1px 2px #be2852;
	animation-delay: 1.1s;
}

.alertContainer {
	width: 45%;
	margin: auto;
}

@keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

@keyframes bounce {
	0% {
		opacity: 0;
		transform: translateY(-1000px) rotate(-45deg);
	}

	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}

	80% {
		transform: translateY(-10px) rotate(-45deg);
	}

	100% {
		transform: translateY(0) rotate(-45deg);
	}
}

@keyframes bounceImg {
	0% {
		opacity: 0;
		transform: translateY(-1000px);
	}

	60% {
		opacity: 1;
		transform: translateY(30px);
	}

	80% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}

@media screen and (max-width: 768px) {

	/* For mobile phones: */
	.pin {
		width: 70px;
		height: 70px;
		border-radius: 50% 50% 50% 0;
		background: #be2852;
		position: absolute;
		/* transform: rotate(-45deg); */
		left: 44%;
		top: 50%;
		margin: -30px 0 0 -4px;
		z-index: 4;
	}

	.pulse {
		background: #d6d4d4;
		border-radius: 50%;
		height: 64px;
		width: 64px;
		position: absolute;
		left: 44%;
		top: 50%;
		margin: 22px 0px 0px -1px;
		transform: rotateX(55deg);
		z-index: 2;
	}

	.imgLogo {
		position: absolute;
		top: 37%;
		left: 24%;
	}

	.alertContainer {
		width: 90%;
		margin: auto;
		margin-right: 27px;
	}
}

.first-container {
	bottom: 0;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	width: 100%;

	padding: 10px;
	background: #fff;
	overflow: hidden;
	/* height: auto; 
	width: 100%; 
	margin: auto; 
	background: #fff; 
	padding: 10px; 
	position: absolute; */
	overflow: auto;
}

/* .container-options-layers {
	width: 100%;
	margin: 5px auto;
	margin-bottom: 5px;
	font-size: 24px;
	text-align: center;
	display: grid;
	grid-gap: 20px;
	gap: 5px;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(1, 1fr);
	justify-content: center;
	align-content: center;
	box-shadow: none;
	margin-bottom: 0;
} */

.widget-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 0;
	z-index: 7;
}

/* NEW DESIGN */
.widget-container-open {
	width: 360px;
	position: absolute;
	top: 0;
	opacity: 1;
	height: 100%;
	left: 0;
	background-color: rgb(243, 243, 243);
	z-index: 7;
	border: 0;
	display: block;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	transition-duration: 0.3s;
	transform: translateX(0px);
}

.widget-container-close {
	transform: translateX(-360px);
}


.widget-toggle-button {
	display: block;
	width: 50px;
	height: 48px;
	margin-left: -2px;
	border-radius: 2px;
	position: absolute;
	top: 16px;
	left: 100%;
	background: white;
	margin: 0;
	padding: 0;
	border: 0;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	transition: display 0s, display 0.5s linear;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
	.widget-container-open {
		width: 100%;
		position: absolute;
		top: 0;
		opacity: 1;
		height: 100%;
		left: 0;
		background-color: rgb(243, 243, 243);
		z-index: 7;
		border: 0;
		display: block;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		transition-duration: 0.3s;
		transform: translateX(0px);
	}

	.widget-container-close {
		transform: translateX(-360px);
		width: 360px;
	}
}

.label-color {
	white-space: normal;
	color: rgba(0, 0, 0, 0.54);
	width: 79px;
}

label {
	display: inline-block;
	margin-bottom: 0px !important;
}

.btn-layers {
	display: inline-block;
	border-radius: 60px;
	box-shadow: 0px 0px 2px #888;
	padding: 0.4em 0.5em;
	background: #e7e7e7;
	width: 50px;
	height: 50px;
	font-size: 18px;
	color: rgb(128, 128, 128);
	border: 0;
	cursor: pointer;
}

/* Generated by Glyphter (http://www.glyphter.com) on  Mon Apr 15 2019*/
@font-face {
	font-family: 'newIcons';
	src: url('../../common/fonts/newIcons.eot');
	src: url('../../common/fonts/newIcons.eot?#iefix') format('embedded-opentype'),
		url('../../common/fonts/newIcons.woff') format('woff'),
		url('../../common/fonts/newIcons.ttf') format('truetype'),
		url('../../common/fonts/newIcons.svg#newIcons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class*='newIcon-']:before {
	display: inline-block;
	font-family: 'newIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.newIcon-capa_d4service:before {
	content: '\005b';
}

.newIcon-capa_d4service-content:before {
	content: '\005b';
}

.newIcon-destino_hecho:before {
	content: '\005c';
	/*color: #3ba935;*/
}

.newIcon-destino_no-entregado:before {
	content: '\005d';
}

.newIcon-destino_pendiente:before {
	content: '\005e';
	/*color: rgba(0, 0, 0, 0.5)*/
}

.newIcon-destino_posible_retraso:before {
	content: '\005f';
	/*color: #eee411;*/
}

.newIcon-hora_de_entrega:before {
	content: '\0060';
}

.newIcon-notificaciom_servicio:before {
	content: '\007b';
}

.newIcon-orden_de_trabajo:before {
	content: '\007c';
}

.newIcon-teimpo_de_entrega:before {
	content: '\007d';
}


@font-face {
	font-family: 'Glyphter';
	src: url('../../common/fonts/Glyphter.woff');
	/* src: url('./fonts/Glyphter.eot?#iefix') format('embedded-opentype'), url('./fonts/Glyphter.woff') format('woff'), url('./fonts/Glyphter.ttf') format('truetype'), url('./fonts/Glyphter.svg#Glyphter') format('svg'); */
	font-weight: normal;
	font-style: normal;
}

[class*='icon-']:before {
	display: inline-block;
	font-family: 'Glyphter';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.icon-circles4:before {
	content: '\0041';
}

.icon-close_red:before {
	content: '\0042';
	width: 100%;
}

.icon-colapse_down:before {
	content: '\0043';
}

.icon-dashboard:before {
	content: '\0044';
}

.icon-date:before {
	content: '\0045';
}

.icon-document:before {
	content: '\0046';
}

.icon-drag_line:before {
	content: '\0047';
}

.icon-duplicate:before {
	content: '\0048';
}

.icon-edit:before {
	content: '\0049';
}

.icon-euro_money_red:before {
	content: '\004a';
}

.icon-fuel_station:before {
	content: '\004b';
}

.icon-fullscren:before {
	content: '\004c';
}

.icon-gauge:before {
	content: '\004d';
}

.icon-gauge_grey:before {
	content: '\004e';
}

.icon-globe:before {
	content: '\004f';
}

.icon-help_grey:before {
	content: '\0050';
}

.icon-layers:before {
	content: '\0051';
}

.icon-like_grey:before {
	content: '\0052';
}

.icon-lines4:before {
	content: '\0053';
}

.icon-list:before {
	content: '\0054';
}

.icon-logout:before {
	content: '\0055';
}

.icon-map:before {
	content: '\0056';
}

.icon-map_view:before {
	content: '\0057';
}

.icon-mapCustom:before {
	content: '\0058';
}

.icon-menu_circle:before {
	content: '\0059';
	width: 100%;
}

.icon-menu_mobile_white:before {
	content: '\005a';
}

.icon-navigator_ico:before {
	content: '\0061';
}

.icon-no_gprs:before {
	content: '\0062';
}

.icon-no_gps:before {
	content: '\0063';
}

.icon-organigram:before {
	content: '\0064';
}

.icon-organigram {
	line-height: 1.5;
}

.icon-parking:before {
	content: '\0065';
}

.icon-poiExclamationMark:before {
	content: '\0066';
}

.icon-poiRfid:before {
	content: '\0067';
}

.icon-radar:before {
	content: '\0068';
}

.icon-road_red:before {
	content: '\0069';
}

.icon-road2:before {
	content: '\006a';
}

.icon-rss:before {
	content: '\006b';
}

.icon-save:before {
	content: '\006c';
}

.icon-search:before {
	content: '\006d';
}

.icon-search_grey:before {
	content: '\006e';
}

.icon-share:before {
	content: '\006f';
}

.icon-sheet_grey:before {
	content: '\0070';
}

.icon-square:before {
	content: '\0071';
}

.icon-star:before {
	content: '\0072';
}

.icon-steering_wheel:before {
	content: '\0073';
}

.icon-stop_sign:before {
	content: '\0074';
}

.icon-time:before {
	content: '\0075';
}

.icon-time_red:before {
	content: '\0076';
}

.icon-tool:before {
	content: '\0077';
}

.icon-track:before {
	content: '\0078';
}

.icon-trafficLights:before {
	content: '\0079';
}

.icon-trash:before {
	content: '\007a';
}

.icon-user_blackwhite:before {
	content: '\0030';
}

.icon-user_grey:before {
	content: '\0031';
}

/* .icon-vehicles {
	color: #a5a5a5;
  } */

.icon-vehicles:before {
	content: '\0032';
}

.icon-vehicles-content:before {
	content: '\0032';
}

.icon-warning_yellow:before {
	content: '\0033';
}

.icon-wireless_grey:before {
	content: '\0034';
}

.icon-4gflota_anagram:before {
	content: '\0035';
}

.icon-4gflota_logo:before {
	content: '\0036';
}

.icon-add_new_plus:before {
	content: '\0037';
}

.icon-adjust_grey:before {
	content: '\0038';
}

.icon-alert:before {
	content: '\0039';
}

.icon-alert_white:before {
	content: '\0021';
}

.icon-ambulance_grey:before {
	content: '\0022';
}

.icon-arrows_double_grey:before {
	content: '\0023';
}

.icon-bag_grey:before {
	content: '\0024';
}

.icon-bubble_speech:before {
	content: '\0025';
}

.icon-bus_gray:before {
	content: '\0026';
}

.icon-chrono:before {
	content: '\0027';
}

.caret-style {
	font-size: 20px;
	color: rgba(0, 0, 0, 0.5);
	margin-right: 0px;

}

/* Controles */
.containerOptions {
	width: 210px;
	background: #fff;
	padding: 15px;
	border-radius: 1px;
	position: absolute;
	z-index: 1000;
	/* height: 385px; */
	left: calc(100% - 210px);
	top: 60px;
	min-height: 336px;
	/* box-shadow: 0px 0px 1px 0px; */
	padding-bottom: 10px;
}

.container-label-options {
	display: flex;
	justify-content: space-between;
	align-content: center;
	margin-left: 3px;
	margin-top: 17px;
	font-size: 14px;
}

.container-label-options>label {
	cursor: pointer;
}

.input {
	margin-bottom: 3px;
	width: 180px;
	padding: 0 0 0 5px;
	border: none;
	position: absolute;
	top: 58px;
}

.displayNone {
	display: none;
}

.h1StyledTop {
	font-size: 11px;
	font-weight: 800;
	margin-bottom: 10px;
}

.h1StyledBottom {
	margin-top: 20px;
	font-size: 11px;
	font-weight: 800;
	margin-bottom: 10px;

}

.labelStyled {
	text-align: center;
	color: #000;
	font-size: 11px;
	cursor: pointer;
}

.containerOptions ul {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
	grid-gap: 21px;
	padding: 0;
}

.containerOptions li {
	background-color: #fff;
	border-radius: 5px;
	padding: 12px;
	font-size: 15px;
	color: #000;
	border: 1px solid #000;
	margin-bottom: 5px;
	list-style: none;
}

.containerOptions .aws-sld__bullets button {
	width: 5px;
	height: 5px;

}

.lineBottom {
	margin-bottom: 8px;
	font-size: 14px;
}

.line-select {
	width: 98%;
	margin-left: 4px;
	margin-top: 2px;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.MuiSwitch-root {
	width: 58px;
	height: 38px;
	display: inline-flex;
	padding: 12px;
	z-index: 0;
	overflow: hidden;
	position: relative;
	box-sizing: border-box;
	flex-shrink: 0;
	vertical-align: middle;
	justify-content: space-between !important;
}

.MuiSwitch-root {
	width: 58px;
	height: 38px;
	display: inline-flex;
	padding: 12px;
	z-index: 0;
	overflow: hidden;
	position: relative;
	box-sizing: border-box;
	flex-shrink: 0;
	vertical-align: middle;
}

select {
	color: #000;
	color: rgba(0, 0, 0, 0);
	text-shadow: 0 0 0 #000;
	width: 100%;
	padding: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-left: 0px;
	padding-bottom: 5px;
	border: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjDAwNHxV7fegTAAAAN0lEQVQoz2NgGAygnOE/HliPX0k9flPq8VtUj98t9fidi1UaoQRFmhlFwVEGRoaDDI0DHfLoAADCH0COZ5Ot8AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMi0xMlQxMzozMToyMSswMDowMHdwQtYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTItMTJUMTM6MzE6MjErMDA6MDAGLfpqAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat right center;
	background-size: auto;
	background-size: 10px;
	font-size: 14px;
}

.MuiTypography-body1 {
	font-size: 15px !important;

}

.MuiFormControlLabel-labelPlacementStart {
	margin-right: -11px;
	flex-direction: row-reverse;
}

.MuiFormGroup-row {
	flex-direction: unset;
}

.MuiFormControlLabel-labelPlacementStart {
	margin-left: 4px !important;
	margin-right: -11px;
	flex-direction: row-reverse;
	justify-content: space-between;
}

input[type="button"] {
	width: 120px;
	height: 60px;
	margin-left: 35px;
	display: block;
	background-color: gray;
	color: white;
	border: none;
	outline: none;
}

button:focus {
	outline: none;
}

/* Spinner 3Balls Scale */
.sp-3balls,
.sp-3balls:before,
.sp-3balls:after {
	border-radius: 50%;
	background-color: rgb(191, 35, 73);
	width: 18px;
	height: 18px;
	transform-origin: center center;
	display: inline-block;
	z-index: 1000;
	top: 450px;
}

.sp-3balls {
	position: relative;
	background-color: rgba(rgb(248, 245, 245), 1);
	opacity: 1;
	-webkit-animation: spScaleAlpha 1s infinite linear;
	animation: spScaleAlpha 1s infinite linear;
}

.sp-3balls:before,
.sp-3balls:after {
	content: '';
	position: relative;
	opacity: 0.40;
}

.sp-3balls:before {
	left: 30px;
	top: 0px;
	-webkit-animation: spScaleAlphaBefore 1s infinite linear;
	animation: spScaleAlphaBefore 1s infinite linear;
}

.sp-3balls:after {
	left: -30px;
	top: -24px;
	-webkit-animation: spScaleAlphaAfter 1s infinite linear;
	animation: spScaleAlphaAfter 1s infinite linear;
}

.location-detail-box.panel {
	position: fixed;
	bottom: 0px;
	width: 100%;
	left: 0;
	right: 0;
	margin: 0 auto;
	visibility: visible;
	transition: height 2s;
}

.MuiSvgIcon-root {
	font-size: 2.5rem !important;
	cursor: pointer;
}

/* .MuiIconButton-label {
	display: unset !important;
} */