.MuiFormGroup-row {
    flex-direction: column !important;
}

.MuiFormControlLabel-labelPlacementStart {
    margin-left: 4px !important;
    margin-right: -11px;
	flex-direction: row-reverse;
	justify-content: space-between;
}

#btn-options > img {
    display: -webkit-inline-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
}

.MuiTooltip-tooltip {
    font-size: 12px !important;
  }

.MuiGrid-root {
    width: calc(100% + 16px) !important;
}

.containerCheck .checkmark:after {
    left: 6px !important;
    top: 2px !important;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.fa-location-arrow > .MuiSvgIcon-root {
    display: block !important;
}