.MuiDataGrid-menuIcon {
  display: none !important;
}

.centered-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0, 20px !important;
}

@media screen and (max-width: 767px) {
  .custom-styles-grid-list {
    padding: 0 !important;



  }
}

@media screen and (max-width: 767px) {
  .custom-styles-grid {
    padding: 0 !important;

    font-size: 12px !important;


  }
}

@media screen and (max-width: 767px) {
  .custom-styles-grid-date {
    padding: 0 !important;
    padding-left: 3px !important;
    font-size: 12px !important;


  }
}

@media screen and (max-width: 767px) {
  .custom-divider {
    margin-left: 16px !important;


  }
}