body {
  background: #d9dadb;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px !important;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.label-color {
  width: 100% !important;
  margin-left: 3px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

.panel-group .panel {
  overflow: visible;
}

.accordion>a {
  cursor: pointer;
}

.MuiSvgIcon-fontSizeLarge {
  font-size: 34.5px !important;
}

.MuiAlert-root {
  font-size: 14px;
  line-height: 1;
}

.MuiSvgIcon-root {
  font-size: 2.5rem !important;
  cursor: pointer;
}

.map-location-2 .list-unstyled {
  width: 70px;
}

.icon-colapse_down {
  transform: rotate(90deg);
}

.MuiSnackbarContent-message {
  font-size: 14px;
}

.MuiMenu-list>.MuiListItem-root {
  font-size: 14px !important;
}

#demo-simple-select {
  font-size: 14px !important;
  min-height: unset;
}

.react-tiger-transition--container {
  overflow: unset !important;
}

.react-tiger-transition--route {
  overflow: unset !important;
}

.popup .popuptext {
  width: 140px;
  background-color: rgb(119, 119, 119);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  right: -82.5px !important;
  top: -70px;
  transition: width 2s;
  height: 27px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: -35px;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}


/* #fade > .MuiGrid-root {
  padding-bottom: 30px;
  padding-top: 15px;
  margin: auto !important;
  width: calc(100% + 16px) !important;
} */

.MuiSnackbarContent-message {
  width: 285px !important;
}

.MuiSnackbar-anchorOriginBottomCenter {
  top: 80vh !important;
}

.MuiListItemIcon-root {
  min-width: 35px !important;
}

.MuiFormGroup-row {
  flex-direction: unset;
}

/* .MuiIconButton-label {
	display: unset !important;
} */
.rotate {
  -webkit-animation: rotate 0.5s normal linear;
  animation: rotate 0.5s normal linear;
  transform: rotate(180deg);
}

.rmc-pull-to-refresh .rmc-pull-to-refresh-content-wrapper {
  text-align: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }

  /* 0% {
    -webkit-transform: rotate(0, 0, 1, 0deg);
    transform: rotate(0deg);
  } */
  /* 25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 30deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 60deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 75deg);
  } */
  /* 100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  } */
}



.hr-theme-slash-2 {
  display: flex;
}

.hr-theme-slash-2>.hr-line {
  width: 100%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.hr-theme-slash-2>.hr-icon {
  position: relative;
  top: 3px;
  color: #32a852;
}



/* *, *::after, *::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
} */

video {
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.video-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
}

.scanner-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(255, 0, 0, 0.8);
  animation: scan 2s infinite;
  z-index: 2;
}

.tableServices .dataTables_scrollHeadInner {
  padding-right: unset !important;
  
}


@keyframes scan {
  0% {
    top: 0;
  }
  50% {
    top: calc(100% - 2px);
  }
  100% {
    top: 0;
  }
}

@media (max-width: 768px) {
  .video-container {
    padding-top: 75%; /* 4:3 aspect ratio */
  }
  .scanner-area {
    width: 90%;
    height: 50%;
  }
}
