.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: lightslategray;
}

.sidebar-map-content .level2-content-bottom {
    margin-top: 2px;
    line-height: 1.7;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.btn-defaultTabla {
    background: #bf2349;
    border: 0;
    border-radius: 0;
    color: #ffffff;
    text-transform: uppercase;
    width: 100px;
    height: 60px;
    font-size: 15px;
    font-weight: 700;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
}

.btnModal {
    background: #fff;
    border: none;
    border: 1px solid #bf2349;
    padding: 8px;
    max-width: 12%;
    min-width: 12%;
    color: #bf2349;
    border-radius: 3px;
    transition: all 0.35s;
}

.sorting:before {
    content: '▼';
}

.sorting_asc:before {
    content: '▲';
}

.sorting_desc:before {
    content: '▼';
}



/* :nth-child(2).sorting:before{
    content: '';
}

:nth-child(2).sorting_asc:before{
    content: '';
} */

/* :nth-child(2).sorting_desc:before{
    content: '';
}

.sorting_desc:before{
    content: '▼';
} */



/* th > :nth-child(2).sorting:before{
    content: 'a';
} */
th> :nth-child(2).sorting:before {
    content: '▼';
}

/* .btnModal:hover {
    border: 1px solid gray;
} */

/* Contenedor principal */
.checkbox-container {
    display: flex;
    justify-content: flex-end;
    /* Alinea al lado derecho */
    align-items: center;
    /* Centra verticalmente */
    margin-top: 20px;
    width: 100%;
    
    /* Asegura que ocupe todo el ancho */
}

/* Estilos para el label */
.checkbox-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    margin-right: 50px;
}

/* Estilos para el checkbox */
.checkbox-input {
    margin: 0;
    /* Elimina márgenes innecesarios */
}

@media (min-width: 1279.98px) {
    .checkbox-container {
        margin-top: 20px;
        margin-bottom: 10px;
        margin-right: 50px;
        /* Aumentar el espacio superior si es necesario */
    }

    .checkbox-label {
        margin-right: 20;
        /* Aumentar tamaño del texto en pantallas grandes */
    }

    .table-responsive {
        /* overflow-x: auto; */
        background-color: white !important;
        /* margin: 15px 50px 50px 50px !important; */
        font-size: 11px;

    }

    .table-responsive>table>thead>tr>th {

        font-size: 16px;
    }

    .table {
        margin-bottom: 0 !important;
    }

    .btnExport {
        margin-right: 50px !important;
        width: 30% !important;
        height: 45px !important;
    }

    .updatedTime {
        margin: 10px 50px 15px 50px !important;
    }

    .btnModal {
        margin: 10px 50px 15px 50px !important;
        max-width: 15%;
        min-width: 12%;
    }

    .datePicker {
        margin-right: 50px !important;
        width: 20% !important;
    }

}

.react-date-picker {
    width: 100% !important;
}

@media (max-width: 1279.98px) {
    .checkbox-container {
        margin-top: 20px;
        margin-bottom: 10px;
        margin-right: 50px;
        /* Reducir el espacio superior */
    }

    .checkbox-label {
        margin-right: 20px;
        /* Reducir tamaño del texto en pantallas pequeñas */
    }

    .table-responsive {
        /* overflow-x: auto; */
        background-color: white !important;
        /* margin: 10px 20px 20px 20px !important; */
        width: auto;
        font-size: 10px;
    }

    .table-responsive>table>thead>tr>th {

        font-size: 16px;
    }

    .table {
        margin-bottom: 0 !important;
    }

    .btnExport {
        margin-right: 20px !important;
        width: 55% !important;
        height: 35px !important;
    }

    .datePicker {
        margin-right: 20px !important;
        width: 20% !important;
        height: 20px !important;
    }

    .updatedTime {
        margin: 10px 20px 15px 20px !important;
    }

    .btnModal {
        margin: 10px 20px 15px 20px !important;
        max-width: 20%;
        min-width: 20%;
    }
}

@media (max-width: 416px) {
    .datePicker {
        /* margin-right: 45px !important; */
        width: 55% !important;
        height: 20px !important;
    }

}

.table thead>tr>th,
.table tbody>tr>th,
.table tfoot>tr>th,
.table thead>tr>td,
.table tbody>tr>td,
.table tfoot>tr>td {
    vertical-align: middle !important;
    padding: 0;
}

/* table, thead, tbody,tr, th, td {
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
}
th
{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 199px;
} */
/* th {
    min-width: 129px;
    max-width: 129px;
} */

.dataTables_scroll {
    padding: 20px !important;
}

.tableContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 40px 35px;
    margin: auto;
}

/* .tableContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100%;
    grid-template-rows: 40px 24px 35px;
} */

.btnExport {
    float: right !important;
    width: 20% !important;
}

.loadingSpinner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: lightgray;
    opacity: 0.7;
}

.loadingSpinnerEdit {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #dbdbdb;
    opacity: 0.7;
}



.basic-multi-select.css-2b097c-container {
    width: 90%;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 2.5%;
    /* animation:  slide-down .1s ease;
    -webkit-animation: slide-down .1s ease;
    -moz-animation: slide-down .1s ease;

    animation:  slide-up .1s ease-in-out;
    -webkit-animation: slide-up .1s ease-in-out;
    -moz-animation: slide-up .1s ease-in-out; */
}

.grid-item {
    background-color: transparent;
    width: 80%;
    margin-top: 15px;

}

#outlined-basic-label {
    font-size: 15px;
    z-index: 0;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    letter-spacing: 1.7px;
    position: static;
}

.form-control.form-check-input {
    position: static;
    opacity: 1;
    width: 100%;
    height: 34px;
    border: none;
}

.paper {
    background: #fff;
    box-shadow: black;
    padding: 20px;
    max-height: 80%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    overflow-y: scroll;
    font-family: "roboto";
}

.modalCustom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dndContainer {
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;

}

.dndTitle {
    padding: 8px;
}

.dndContainerContent {
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.dndContent {
    /* border: 1px solid lightgrey;
    border-radius: 2px;
    padding: 8px;
    margin-bottom: 8px;
    /* margin-left: 8px; */
    font-size: 14px;
    font-weight: 400 !important;
    overflow: hidden;

    /* width: 94%; */
}

.tableServices,
.tableServices th,
.tableServices td:nth-child(1) {
    text-align: center;
}

.tableServices td {
    white-space: pre-line;
}

.grid-container-planing-btn {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 10px;
    width: 40%;
    margin: auto;
}

.grid-container-planing-btn>div {
    text-align: center;
    width: 100%;
    /* width: 50%; */
    /* font-size: 30px; */
}

.contentModalChangeMassive {
    background: #fff;
    box-shadow: black;
    padding: 20px;
    max-height: 80%;
    width: 50%;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-rows: 70px 1fr 1fr;
    overflow: hidden;
    /* overflow-y: scroll; */
    font-family: "roboto";
}

.contentModalChangeMassive>div {
    margin: auto;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 70px 1fr 1fr 1fr;
    width: 80%;
    word-wrap: break-word;

}

.contentModalChangeMassive>.orders {
    margin: auto;
    display: grid;
    grid-template-columns: 200px;
    /* grid-template-rows: 70px 1fr 1fr 1fr; */
    width: 80%;
    word-wrap: break-word;

}

.contentModalChangeMassive>div>div>label {
    position: relative;
    bottom: 26px !important;
}

.containerBtn {
    display: grid;
    grid-template-columns: 200px 200px;
    gap: 10px;
    margin: auto;
    margin-top: 15px;
}

/* .css-2b097c-container{
    height: 35px;
} */

/* .map-mobile-filter > .css-2b097c-container > .css-yk16xz-control {
    border-color: #bf2349 !important;
} */

.map-mobile-filter>.css-2b097c-container>.css-1pahdxg-control {
    border-color: #bf2349 !important;
    box-shadow: 0 0 0 1px #bf2349 !important;
    min-height: 40px !important;
}

.map-mobile-filter>.basic-multi-select {
    min-height: 43px !important;
}

.select__menu {
    /* height: 50px; */
    z-index: 5;
}

.btn {
    z-index: 0;
}

.containerBtn>button {
    width: 100%;
    height: 40px;
}


/* td:nth-child(9) {
    text-align: center;
}

td:nth-child(10) {
    text-align: center;
} */

/* .MuiFormGroup-root.MuiFormGroup-row {
    width: 3%;
    margin-left: 1.5%;
} */

/* .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-marginDense.MuiInputLabel-outlined */

/* .select__value-container.select__value-container--is-multi.css-g1d714-ValueContainer{
    padding: 6px 8px;
    max-width: 300;
} */

/* The container */
.containerLabelCheckBox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 30px;
    margin-top: 10px;
}

/* Hide the browser's default checkbox */
.containerLabelCheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
}

/* On mouse-over, add a grey background color */
.containerLabelCheckBox:hover input~.checkmark {
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.containerLabelCheckBox input:checked~.checkmark {
    background-color: #bf2349;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerLabelCheckBox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerLabelCheckBox .checkmark:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ------------------- CHECKBOX FOR TABLE ------------------- */

.containerLabelCheckBoxtable {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 30px;
    margin-top: 10px;
    margin-right: 35px;
}

/* Hide the browser's default checkbox */
.containerLabelCheckBoxtable input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.containerLabelCheckBoxtable>.checkmark {
    position: absolute;
    top: 0;
    left: 30px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.containerLabelCheckBoxtable:hover input~.checkmark {
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.containerLabelCheckBoxtable input:checked~.checkmark {
    background-color: #bf2349;
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerLabelCheckBoxtable input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerLabelCheckBoxtable .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

tbody+thead {
    display: none;
}


@media screen and (max-width: 767px) {
    .basic-multi-select.css-2b097c-container {
        width: 100%;
    }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 5%;
    }

    .grid-item {
        background-color: transparent;
        width: 90%;
        margin-top: 15px;
    }

    .btnModal {
        margin: 10px 20px 15px 20px !important;
        max-width: 50%;
        min-width: 40%;
    }

    /* .MuiFormGroup-root.MuiFormGroup-row {
        width: 3%;
        margin-left: 0.7%;
    } */
}

@media (min-width: 768px) and (max-width: 1164px) {
    .basic-multi-select.css-2b097c-container {
        width: 100%;
    }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;

    }

    .grid-item {
        background-color: transparent;
        width: 91%;
        margin-top: 15px;
    }

    /* .MuiFormGroup-root.MuiFormGroup-row {
        width: 3%;
        margin-left: 0.7%;
    } */
}

.MuiAlert-root {
    line-height: 1.2 !important;
}

.MuiAlert-icon {
    padding: 10px 0 !important;
    margin-top: 2px !important;
}

.MuiAlert-message {
    padding: 10px 0 !important;
    margin-top: 5px !important;
}

#tableServicesId td {
    max-width: 132px !important;
    min-width: 30px !important;
}

.tableServices .dataTables_scrollHeadInner {
    box-sizing: unset !important;
    padding-right: 1px !important;
}

/* @keyframes slide-down {
    0% { opacity: 0; -webkit-transform: translateY(-100%); }   
  100% { opacity: 1; -webkit-transform: translateY(0); }
}

@-webkit-keyframes slide-down {
    0% { opacity: 0; -webkit-transform: translateY(-100%); }   
  100% { opacity: 1; -webkit-transform: translateY(0); }
}
@-moz-keyframes slide-down {
    0% { opacity: 0; -moz-transform: translateY(-100%); }   
  100% { opacity: 1; -moz-transform: translateY(0); }
}

@keyframes slide-up {
    100% { opacity: 1; -webkit-transform: translateY(0); }
    0% { opacity: 0; -webkit-transform: translateY(-100%); }
}
@keyframes slide-up {
    100% { opacity: 1; -webkit-transform: translateY(0); }
    0% { opacity: 0; -webkit-transform: translateY(-100%); }
}
@keyframes slide-up {
    100% { opacity: 1; -webkit-transform: translateY(0); }
    0% { opacity: 0; -webkit-transform: translateY(-100%); }
} */