
/* @media (min-width: 768px){
	.modal-header{border-bottom: 0; margin-bottom: 45px; padding-top: 0; padding-bottom: 0;}
	.modal-dialog{width: 100%;}
} */
.basic-multi-select.css-2b097c-container {
	width: 100% !important;
}
@media screen and (max-width: 1115px){
	/* Link */
	.link-container .btn-default{padding: 0 35px;}
	.link-content ul li .details label{width: 25%;}
	.link-content ul li .details .content{width: 75%;}
	/* aÃ±adido por jose */
	.iconExclamation {position: absolute; top: 46%; left:20px; width: 30px; height: 30px;}
}

@media screen and (max-width: 991px){
	.inner-container{padding: 0 0 80px 0; margin: 0;}
	.map-container{margin: 0; position: relative; background: #ffffff; padding: 0 0 80px 0;}
	.map-container .map-left{padding: 0; padding: 50px 0 0 0;}
	.map-container .map-right{padding: 30px; background: #ffffff; position: static;}
	.map-content{padding: 0;}
	.map-images{margin: 30px 0 0 0; padding: 0; border-top: 1px solid #939598; border-bottom: 1px solid #939598;}
	.map-images ul{padding: 20px 0;}
	.map-images ul li{margin-bottom: 0;}
	.map-details{position: absolute; top: 7px; left: 0; width: 100%;}
	.map-content .map-details li{margin-bottom: 0; float: left; padding-left: 20px;}
	.map-content .fa-arrows-alt-h{font-size: 19px;}
	.map-content .map-details li.measurement{font-size: 16px; margin-top: 5px; position: absolute; left: 50%; padding: 0 10px; transform: translateX(-50%); -moz-transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%);}
	.map-content ul li.measurement .icon{margin-top: 2px; width: auto; margin-right: 5px;}
	.map-content .map-details li.datetime{font-size: 16px; margin-top: 6px; float: right; padding: 0 20px 0 0;}
	.map-content .map-details li.datetime .date{margin: 0 5px 0 0;}
	.check{text-align: left; width: auto; margin-right: 10px;}
	.map-content .btn-default{position: absolute; bottom: 30px; margin: 0; z-index: 10; left: 50%; transform: translateX(-50%); -moz-transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%);}
	.map-content ul li .icon{text-align: left; width: 38px;}
	.map-content ul li .icon .fa-user{margin: 0 0 0 2px;}
	/* .modal.in .modal-dialog{padding: 0; margin: 0;} */
	/* .popup-map-container{background: #bcbdc0;}
	.popup-map-content{padding: 10px 20px;}
	.popup-place-image{padding: 0;} */
	/* .modal-header{margin-bottom: 0;}
	.modal-body{padding-top: 0; padding-bottom: 0;}
	.modal-header .close{font-size: 45px;} */
	

	/* Link */
	.link-main-container{padding: 20px 15px 50px;}
	.link-container{width: 100%; padding: 0; border-radius: 15px;}
	.link-header{padding: 0; position: relative; background: #e7e7e8; border-radius: 15px 15px 0 0;}
	.link-container .btn-container{width: 100%; float: none; padding: 5px 0;}
	.link-container h1{width: auto; float: none; margin: 0; position: absolute; font-size: 15px; top: 50%; left: 50%; transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%);}
	.link-container .btn-default{margin: 0;}
	.link-container .btn-default:focus{outline: none; background: none; box-shadow: none;}
	.link-container .btn-default.btn-cancel{float: left;}
	.link-container .btn-default.btn-link{float: right;}
	.link-container .btn-default{background: none; color: #c0234a; padding: 0 20px; height: 35px;}
	.link-container .btn-default svg{display: none;}
	.link-content{margin: 0; padding: 0 0 10px 0;}
	.link-content ul .details{padding: 0 20px;}

	/* aÃ±adido por jose */
	.iconExclamation {position: absolute; top: 47%; left:20px; width: 30px; height: 30px;}
}

@media screen and (max-width: 767px){
	.navbar-default{padding-top: 10px;}
	.navbar-right{padding: 20px 0 0 0;}
	.navbar-default .navbar-collapse, .navbar-default .navbar-form{background: #ffffff; width: 90%; margin: 0 auto; min-height: 390px; z-index: 1; position: absolute; left: -100%; transition: 0.5s ease; -moz-transition: 0.5s ease; -webkit-transition: 0.5s ease; -o-transition: 0.5s ease; -ms-transition: 0.5s ease;}
	.header-container .navbar-default .navbar-collapse{left: -110%;}
	.navbar-default .navbar-collapse.in{left: 0; right: 0; transition: 0.5s ease; -moz-transition: 0.5s ease; -webkit-transition: 0.5s ease; -o-transition: 0.5s ease; -ms-transition: 0.5s ease;}
	/* .navbar-nav>li::after{width: 100%; height: 1px; margin: 0; top: auto;} */
	.navbar-nav > li::after{
		width: 100% !important;
		height: 1px !important;
		margin: 0 !important;
		top: 53px !important;
	}
	.navbar-nav>li:hover::after{background: #c0234a;}
	.navbar-nav>li:last-child::after, .header-container .navbar-nav>li::after{display: block;}
	.navbar-default .navbar-nav>li>p::after{display: none;}
	.navbar-nav>li{font-size: 18px;}
	.navbar-default .navbar-nav > li > p{padding: 15px 35px !important; cursor: pointer !important;}
	.main-form-container{position: relative; transform: translate(0, 0); -moz-transform: translate(0, 0); -webkit-transform: translate(0, 0); -o-transform: translate(0, 0); -ms-transform: translate(0, 0); left: 0; top: 0; padding-bottom: 50px; margin-top: 30px;}
	.nav-title{display: block;}
	.header-container .nav-title{padding: 0 15px;}
	.header-container .navbar-toggle{float: left; margin: 0; padding: 0;}
	.header-container .container-fluid{padding-left: 0; padding-right: 0;}
	.header-container .navbar-header{margin: 0;}
	.header-container .navbar-default .navbar-toggle .icon-bar{background: #ffffff;}
	.header-container{padding: 20px 15px;}
	.header-links{margin: 0;}
	.header-links ul li{margin-right: 20px;}
	.header-container .navbar-header{float: left; margin: 16px 0 0 0;}
	.header-container .logo-container{position: absolute; left: 50%; transform: translateX(-50%); -moz-transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%);}
	.header-container .logo-container img{max-width: 150px;}
	.header-container .navbar-default .navbar-nav>li>p{color: #000000;}
	.header-container .navbar-nav>li{position: relative;}
	.header-container .navbar-default .navbar-collapse{float: none; margin: 0; padding: 0; top: 50px; width: 100%;}
	.header-container .navbar-right{margin: 0;}
	.header-container .navbar-default .navbar-nav>li>p:hover{color: #cd0438;}
	.modal-header{border-bottom: 0; padding: 0;}
	.navbar .container-fluid{padding: 0 20px;}
	.header-container .navbar .container-fluid{padding: 0;}
	/* .navbar-nav > li::after{
		width: 100% !important;
		height: 1px !important;
		margin: 0 !important;
		top: auto !important;
	} */
	/* Link */
	.link-content ul li .details li{font-size: 16px;}
	.link-content ul li .details label{width: 30%;}
	.link-content ul li .details .content{width: 70%;}
	/* .styledSelect{font-size: 16px; background: #ffffff url("../images/down-arrow-small.png") no-repeat 100% 11px;} */
	.styledSelect:hover{background-position: 100% -9px;}
	.select-box{width: 80%;}
	.select-box .select{height: 28px;}
	.select-box .options{top: 36px;}
}



@media screen and (max-width: 480px){
	
	.logo-container img{width: 180px;}
	/* .form-container{margin-top: 50px;} */
	.navbar-default .navbar-collapse, .navbar-default .navbar-form{min-height: 350px;}
	.header-container{padding: 20px 15px; min-height: 80px;}
	.header-links ul li{font-size: 20px; margin-right: 10px;}
	.header-container .logo-container img{max-width: 130px;}
	.header-container .navbar-header{margin-top: 10px;}
	.header-links-container{margin-top: 6px;}
	.header-container .navbar{min-height: 30px;}
	.map-content ul li{font-size: 16px;}
	.map-content ul li .icon{width: 13%;}
	.map-container .map-left{padding-top: 45px;}
	.map-content .map-details li{padding-left: 10px;}
	.map-content .map-details li.datetime{padding-right: 10px;}
	.check{font-size: 12px; margin-right: 6px; margin-top: 6px;}
	.check-content{font-size: 20px;}
	.map-content .map-details li.measurement{padding: 0; font-size: 14px; margin-top: 2px;}
	.map-content .map-details li.datetime{font-size: 14px; margin-top: 3px;}
	.map-content .map-details li.datetime .date{margin-right: 2px;}
	.map-content .fa-user, .map-content .fa-truck{font-size: 16px;}
	.map-container .map-right{padding: 20px;}
	.map-images{margin-top: 20px;}
	.map-images ul{padding: 10px 0;}
	
	/* aÃ±adido por jose */
	.iconExclamation {position: absolute; top: 37%; left:20px; width: 30px; height: 30px;}
	.aButton {height:45px; line-height: 2.2; width: 67%; margin-left: 49px;}
}

@media screen and (max-height: 480px){
	.main-form-container{position: relative; transform: translate(0, 0); -moz-transform: translate(0, 0); -webkit-transform: translate(0, 0); -o-transform: translate(0, 0); -ms-transform: translate(0, 0); left: 0; top: 0; padding-bottom: 50px;}
}

@media (min-width: 992px){
	.swiper-container{overflow: visible;}
	.map-images ul{transform: none!important; -moz-transform: none!important; -webkit-transform: none!important; -o-transform: none!important; -ms-transform: none!important; display: block;}
	.map-images ul li{display: block; width: auto!important; margin-right: 10px!important;}
	.swiper-pagination, .map-details .datetime{display: none;}
}

.awssld div {
	--slider-height-percentage: 55%;
	--content-background-color: #4287f5;
	color: #000;
  }

.awssld__bullets {
    position: absolute;
    bottom: -16px;
    width: 100%;
    display: flex;
    align-items: center;
	justify-content: center;
}
h1{
	margin-top:0;
}

